import logo from './logo.svg';
import './App.css';
import React from 'react';
import Content from './components/Content';

class App extends React.Component {

  app = {
    display: 'flex',
    flexDirection: 'column',
    padding: '1.4rem',
  };

  title = {
    textAlign: 'center',
  }
  
  render() {

    return (
      <div style={this.app}>
  
        <h2 style={this.title}>聯合分發 - 志願填選系統</h2>

        <Content/>

      </div>
    );
  }

}

export default App;
