
import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Select extends Component {

    static propTypes = {
        select: PropTypes.array,
        deleteSelectItem: PropTypes.func,
        setInputValue: PropTypes.func,
    }

    container = {
        border: '2px solid #DEE7F4',
        borderRadius: '6px',
        boxSizing: 'border-content',
    }

    title = {
        background: "#EEE",
        padding: "1rem 1.8rem",
        fontSize: "1.1rem"
    }

    box = {

    }

    school = {

    }

    button = {

    }

    // function


    render() {
        const { select, deleteSelectItem, setInputValue} = this.props;

        return (
            <div style={this.container}>

                <div style={this.title}>已填選志願</div>

                <div style={{ padding: "1rem" }}>
                    {
                        select.map((school, index) => (
                            <div className='box' key={index} uuid={school.department + index}>

                                {/* 學群訊息 */}
                                <div>
                                    <p className='shcoolName'>{school.name}</p>
                                    <p className='deparmentName'>{school.department}</p>
                                </div>

                                {/* 按鈕 */}
                                <div className='buttonArea'>
                                    <input
                                        value={index + 1}
                                        onChange={(event) => setInputValue(event.target.value, index, school)}
                                    />
                                    <button
                                        className='button'
                                        style={{ background: "red" }}
                                        onClick={() => deleteSelectItem(index)}
                                    >
                                        X
                                    </button>
                                </div>
                            </div>
                        ))
                    }

                </div>
            </div>
        )
    }

}