
import React, { Component } from 'react';
import UniversityJson from './University.json';
import School from './School';
import Select from './Select';

export default class Content extends Component {

    state = {
        university: UniversityJson,
        select: [],
    }

    addToSelect = (school) => {
        const select = this.state.select;
        select.push(school);

        this.setState({
            select
        });
    }

    deleteSelectItem = (index) => {
        const select = this.state.select;
        select.splice(index, 1);

        this.setState({
            select
        });
    }

    setInputValue = (value, index, school) => {
        const select = this.state.select;
        const updatedSelect = select.map((item, i) => {
            if (i === index && item.department === school.department) {
                return { ...item, value: value };
            }
            return item;
        });

        this.setState({
            select: updatedSelect
        });
    }

    section = {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: "0 2rem",
        boxSizing: 'border-content',
    }

    container = {
        border: '2px solid #DEE7F4',
        borderRadius: '6px',
        boxSizing: 'border-content',
    }

    title = {
        background: "#EEE",
        padding: "1rem 1.8rem",
        fontSize: "1.1rem"
    }

    render() {
        const { university, select } = this.state;

        return (
            <div style={this.section} >

                <div style={this.container}>

                    <div style={this.title}>招生校系清單</div>

                    <div style={{ padding: "1rem" }}>
                        {
                            university.map((school, index) => (
                                <div key={index}>
                                    <School
                                        school={school}
                                        select={select}
                                        addToSelect={this.addToSelect}
                                    />
                                </div>
                            ))
                        }
                    </div>

                </div>

                <Select style={this.container} select={select} deleteSelectItem={this.deleteSelectItem} setInputValue={this.setInputValue} />

            </div>
        )

    }

}
